<template>
  <div class="wrapper" id="loadfail">
    <ctheader
      :titles="titles"
      :back-btn="backBtn"
      :back-path="backPath"
    ></ctheader>
    <div class="content">
      <div class="fail"></div>
      <div class="fail-text">
        加载数据失败，请<span @click="reload">刷新</span>试试
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @module app/load/fail
 * @desc 传蔬农贸版后台 加载失败页面
 * @version 0.0.1
 * @author songkexin <songkexin@rongyi.com>
 * @date   2021-01-10
 * @copyright 2021
 */
import router from "@/router";
// import ctheader from '@@/ctheader';
import ctheader from "../../components/ctheader";
export default {
  name: "fail",
  components: { ctheader },
  data() {
    return {
      toPath: "",
      titles: [],
      backBtn: false,
      backPath: "",
    };
  },
  methods: {
    /**
     * 刷新进入失败页的页面
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-01-10
     */
    reload() {
      router.push(this.toPath);
    },
  },
  mounted() {
    this.titles = JSON.parse(localStorage.getItem("loadFailTitles"));
    if (localStorage.getItem("loadFailBackBtn") === "false") {
      this.backBtn = false;
    } else {
      this.backBtn = true;
    }
    this.backPath = localStorage.getItem("loadFailBackPath");
    this.toPath = localStorage.getItem("loadFailToPath");
  },
};
</script>

<style lang="less">
@import url("../../less/common.less");
#loadfail {
  .pos-r();
  .fail {
    position: absolute;
    left: calc(~"50% - 70px");
    top: calc(~"50% - 63px");
    .wh(140px, 106px);
    background-image: url("/images/load/fail.png");
  }
  .fail-text {
    position: absolute;
    left: calc(~"50% - 75px");
    top: calc(~"50% + 53px");
    .fc(12px, #999999);
    > span {
      color: #0d39d0;
      .cs();
    }
  }
}
</style>
